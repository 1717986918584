import React from "react"
import PropTypes from "prop-types"
import Masonry from "react-masonry-component"
import classnames from "classnames"
import "./styles.css"
import {
  forceRefreshRender,
  getImageThumbnailUrl,
  THUMBNAIL_SIZE
} from "../../utils"
import Loader from "../Loader"

export default class Showcase extends React.Component {
  static propTypes = {
    gallery: PropTypes.array
  }

  state = { isLoading: true, opacity: true }

  componentDidMount() {
    this.timer = setTimeout(() => {
      this.setState({ isLoading: false })
      forceRefreshRender()
    }, 300)
  }

  componentWillUnmount() {
    this.timer && clearTimeout(this.timer)
  }

  onLayoutComplete = () => {
    if (this.state.opacity) this.setState({ opacity: false })
  }

  renderItemActions = (itemUrl) => {
    const {
      removeButton,
      onRemoveButtonClick,
      additionalAction,
      onAdditionalActionClick
    } = this.props

    if (!removeButton && !additionalAction) return null

    return (
      <React.Fragment>
        {removeButton ? (
          <div
            onClick={(e) => {
              e && e.stopPropagation()
              onRemoveButtonClick(itemUrl)
            }}
          >
            {removeButton}
          </div>
        ) : null}
        {additionalAction ? (
          <div
            onClick={(e) => {
              e && e.stopPropagation()
              onAdditionalActionClick(itemUrl)
            }}
          >
            {additionalAction}
          </div>
        ) : null}
      </React.Fragment>
    )
  }

  renderItem = ({ videoLink, previewLink, url, name }) => {
    if (videoLink) {
      return (
        <div
          key={videoLink}
          className="mt-30 col-lg-4 col-md-6 col-12 d-block link showcase-item"
        >
          <img
            srcSet={`${previewLink}`}
            src={previewLink}
            alt=""
            className="img-fluid radius10"
          />
          <a
            href={videoLink}
            className="play action-1"
            data-fancybox="showcase_4"
          >
            <i className="fas fa-play" />
          </a>
          {this.renderItemActions(url)}
        </div>
      )
    }
    if (!videoLink && name?.includes("youtube")) {
      return (
        <div
          key={url}
          className="mt-30 col-lg-4 col-md-6 col-12 d-block link showcase-item"
        >
          <img
            srcSet={`${url}`}
            src={url}
            alt=""
            className="img-fluid radius10"
          />
          <a
            href={name}
            className="play action-1"
            data-fancybox="showcase_4"
          >
            <i className="fas fa-play" />
          </a>
          {this.renderItemActions(url)}
        </div>
      )
    }

    return (
      <a
        key={url}
        data-fancybox="showcase_4"
        href={url}
        onClick={(e) => e && e.preventDefault()}
        className="mt-15 mt-md-30 col-lg-4 col-md-6 col-12 d-block link w-full overflow-hidden showcase-item"
      >
        <img
          srcSet={`${getImageThumbnailUrl(url, THUMBNAIL_SIZE.SMALL)} 2x`}
          src={getImageThumbnailUrl(url, THUMBNAIL_SIZE.SMALL)}
          className="radius6 img-fluid w-full"
          alt={name}
        />
        {name && (
          <div className="showcase-item__title">
            <span>{name}</span>
          </div>
        )}
        {this.renderItemActions(url)}
      </a>
    )
  }

  render() {
    const { gallery = [], customItemRender } = this.props

    if (this.state.isLoading) {
      return <Loader />
    }

    return (
      <section className="showcase_4 bg-light text-center color-main pb-50">
        <div className="container px-xl-0">
          {this.state.opacity ? (
            <div className="absolute h-full w-full d-flex justify-content-center">
              <Loader />
            </div>
          ) : null}
          <Masonry
            className={classnames(
              "row align-items-lg-stretch align-items-center",
              {
                "op-0": this.state.opacity
              }
            )}
            onLayoutComplete={this.onLayoutComplete}
            options={{ percentPosition: 30 }}
          >
            {gallery.map(customItemRender ? customItemRender : this.renderItem)}
          </Masonry>
        </div>
      </section>
    )
  }
}
