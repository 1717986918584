import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

const CallOrderButton = ({
  label = 'Заказать звонок',
  onClick,
  className = '',
  animated = true,
  animationDelay = 450,
  type = 'call'
}) => {
  switch (type) {
    case 'price': {
      return (
        <div
          className={classnames('d-flex align-items-center', className)}
          {...(animated
            ? {
                'data-aos-duration': 300,
                'data-aos': 'fade-down',
                'data-aos-delay': animationDelay
              }
            : {})}>
          <span
            className="btn w-40 px-0 sm border-action-1 medium mr-10"
            data-toggle="modal"
            data-target="#demoModal">
            <i className="fas fa-link" />
          </span>
          <a
            href="/"
            className="link action-1 medium f-16"
            onClick={onClick}
            data-toggle="modal"
            data-target="#demoModal">
            {label}
          </a>
        </div>
      )
    }
    case 'call':
    default: {
      return (
        <span
          {...(animated
            ? {
                'data-aos-duration': 300,
                'data-aos': 'fade-down',
                'data-aos-delay': animationDelay
              }
            : {})}>
          <a
            href="/"
            onClick={onClick}
            className={classnames('btn', className || 'action-1')}
            data-toggle="modal"
            data-target="#demoModal">
            {label}
          </a>
        </span>
      )
    }
  }
}

CallOrderButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['price', 'call']),
  className: PropTypes.string,
  animated: PropTypes.bool,
  animationDelay: PropTypes.number
}
export default CallOrderButton
