export const CATEGORIES = {
  DEFAULT: "",
  TILE: "tile",
  SANITARY: "sanitary",
  SAUNA: "sauna",
  STONE: "stone",
  HEATING: "heating",
  MOSAIC: "mosaic"
}

export const CATEGORIES_LABEL = {
  [CATEGORIES.DEFAULT]: "Все направления",
  [CATEGORIES.TILE]: "Плитка",
  [CATEGORIES.SANITARY]: "Сантехника",
  [CATEGORIES.SAUNA]: "Сауна",
  [CATEGORIES.STONE]: "Камень",
  [CATEGORIES.HEATING]: "Отопление",
  [CATEGORIES.MOSAIC]: "Мозаика"
}

export const ITEMS_TYPES = {
  DEFAULT: "",
  NEW: "new",
  PROMO: "promo",
  IN_STOCK: "in_stock",
  SALE: "sale",
  NOT_IN_STOCK: "not_in_stock",
  SOLD: "sold",
  LENINSKY: "leninsky"
}

export const ITEMS_LABEL_BY_TYPES = {
  [ITEMS_TYPES.DEFAULT]: "Все",
  [ITEMS_TYPES.NEW]: "Новинки",
  [ITEMS_TYPES.PROMO]: "Акции",
  [ITEMS_TYPES.IN_STOCK]: "В наличии",
  [ITEMS_TYPES.SALE]: "Распродажи",
  [ITEMS_TYPES.NOT_IN_STOCK]: "Нет в наличии",
  [ITEMS_TYPES.SOLD]: "Продано",
  [ITEMS_TYPES.LENINSKY]: "Ленинский"
}

export const PRODUCT_TYPE = {
  DEFAULT: "",
  TOILET: "toilet",
  SINK: "sink",
  MIXER: "mixer",
  TOWEL_DRYER: "towel_dryer",
  SHOWER: "shower",
  BATH: "bath",
  FURNITURE: "furniture",
  SET: "set",
  ACCESSORY: "accessory"
}

export const PRODUCT_LABEL_BY_TYPE = {
  [PRODUCT_TYPE.DEFAULT]: "Все",
  [PRODUCT_TYPE.TOILET]: "Унитаз",
  [PRODUCT_TYPE.SINK]: "Раковина",
  [PRODUCT_TYPE.MIXER]: "Смеситель",
  [PRODUCT_TYPE.BATH]: "Ванна",
  [PRODUCT_TYPE.SHOWER]: "Душ",
  [PRODUCT_TYPE.TOWEL_DRYER]: "Полотенцесушитель",
  [PRODUCT_TYPE.FURNITURE]: "Мебель для ванной комнаты",
  [PRODUCT_TYPE.SET]: "Комплект",
  [PRODUCT_TYPE.ACCESSORY]: "Аксессуар"
}

export const ROUTES = {
  HOME: "/",
  ABOUT: "/about",
  BRANDS: "/brands",
  BRAND: "/brands/:id",
  CONTACT: "/contact",
  ITEM: "/item/:id",
  BRAND_COLLECTION: "/collection/:id",
  HEATING: `/brands?aspect=${CATEGORIES.HEATING}`,
  MOSAIC: `/brands?aspect=${CATEGORIES.MOSAIC}`,
  NEWS: "/news",
  ARTICLE: "/news/:id",
  PROJECTS: "/projects",
  PROJECT: "/projects/:id",
  SALES: "/sales",
  SANITARY: `/brands?aspect=${CATEGORIES.SANITARY}`,
  SAUNA: `/brands?aspect=${CATEGORIES.SAUNA}`,
  SERVICES: "/services",
  STONE: `/brands?aspect=${CATEGORIES.STONE}`,
  TILES: `/brands?aspect=${CATEGORIES.TILE}`
}

export const ROUTE_BY_CATEGORY = {
  [CATEGORIES.DEFAULT]: ROUTES.HOME,
  [CATEGORIES.TILE]: ROUTES.TILES,
  [CATEGORIES.SANITARY]: ROUTES.SANITARY,
  [CATEGORIES.SAUNA]: ROUTES.SAUNA,
  [CATEGORIES.STONE]: ROUTES.STONE,
  [CATEGORIES.HEATING]: ROUTES.HEATING,
  [CATEGORIES.MOSAIC]: ROUTES.MOSAIC
}

export const TEXT_LENGTH = {
  ALL_NEWS: 200,
  MAIN_NEWS: 100
}

export const CURRENCY = {
  RUB: "Р", //'₽'
  EUR: "EUR",
  USD: "USD"
}

export const CONDITIONS_LINK =
  "https://storage.yandexcloud.net/san4u/69fe872b-250b-4e03-bd04-d3967041987f.pdf"

export const ADMIN_URL = {
  TEST: "https://admin-san4u-test.nyf-nyf.ru",
  PROD: "https://admin.san4u.ru"
}

export const BASE_URL = {
  TEST: "https://san4u-test.nyf-nyf.ru/",
  PROD: "https://san4u.ru/"
}
