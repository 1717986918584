import React from 'react'
import PropTypes from 'prop-types'
import { getImageThumbnailUrl, THUMBNAIL_SIZE } from '../../utils'
import classnames from 'classnames'
import './styles.css'

const GalleryThumbnails = ({ images = [], className = '' }) => (
  <div
    className={classnames(
      'gallery-thumbnails-container col-lg-1 col-md-8 col-sm-10 order-1 order-lg-0',
      className
    )}
    data-aos-duration={300}
    data-aos="fade-down"
    data-aos-delay={300}>
    {images &&
      images.slice(1).map(({ url, name, title }) => (
        <a
          key={url}
          data-fancybox="ecommerce_31_gallery"
          data-loop="true"
          href={url}
          className="mb-15 d-inline-block d-lg-block relative img_link">
          <img
            srcSet={getImageThumbnailUrl(url, THUMBNAIL_SIZE.SMALL)}
            src={getImageThumbnailUrl(url, THUMBNAIL_SIZE.SMALL)}
            alt={name}
            title={title ? title : ""}
            className="img-fluid radius10"
          />
        </a>
      ))}
  </div>
)

GalleryThumbnails.propTypes = {
  images: PropTypes.arrayOf({
    url: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  className: PropTypes.string
}
export default GalleryThumbnails
