import React, { useState } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { Link } from "react-router-dom"
import "./styles.css"
import { Routes } from "../../utils"

const SearchInput = ({
  inputProps,
  className,
  value,
  onChange,
  withSuggest,
  suggestData
}) => {
  const [suggestOpened, setSuggestOpened] = useState(true)

  return (
    <span className="relative">
      <input
        onChange={onChange}
        onBlur={() => {
          setTimeout(() => setSuggestOpened(false), 200)
        }}
        onFocus={() => setSuggestOpened(true)}
        value={value}
        className={classnames(
          'input sm border-gray focus-action-1 color-heading placeholder-heading text-center',
          className
        )}
        placeholder="Поиск"
        type="text"
        autoComplete="on"
        {...inputProps}
      />
      {withSuggest && suggestOpened && suggestData.length > 0 ? (
        <div className="input-suggest w-full">
          {suggestData.map(({ name, url }) => (
            <div className="input-suggest__item w-full" key={url}>
              <Link
                to={Routes.exactBrand.build({ id: url })}
                className="link color-heading lh-30 text-capitalize text-truncate m-0 w-full text-center">
                {name}
              </Link>
            </div>
          ))}
        </div>
      ) : null}
    </span>
  )
}

SearchInput.propTypes = {
  inputProps: PropTypes.object,
  className: PropTypes.string,
  value: PropTypes.string,
  withSuggest: PropTypes.bool,
  suggestData: PropTypes.array,
  onChange: PropTypes.func.isRequired
}
SearchInput.defaultProps = {
  inputProps: {},
  className: '',
  value: '',
  withSuggest: false,
  suggestData: []
}
export default SearchInput
