import React from "react"
import classnames from "classnames"
import PropTypes from "prop-types"

const EmptySection = (props) => (
  <div className={classnames(props.className)}>
    <section className="ecommerce_31 bg-light pt-100 pb-50">
      <div className="container px-xl-0">
        <div className="row flex-column flex-lg-row align-items-center justify-content-center">
          <img
            src={props.icon || "/i/not_found.svg"}
            alt="Ничего не найдено"
            className="mw-270"
          />
          {props.title ? (
            <div className="mt-30 color-main f-22 semibold title">
              <span>{props.title}</span>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  </div>
)

EmptySection.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.blob])
}
EmptySection.defaultProps = {
  className: "",
  title: "Ничего не найдено"
}
export default EmptySection
