import React from 'react'
import './styles.css'

export default class SocialNetworks extends React.Component {
  render() {
    const { decoration } = this.props

    return (
      <div className="social-networks">
        <a
          href="https://t.me/san4uru"
          target="_blank"
          rel="noopener noreferrer"
          className="w-40 w-md-50 h-md-50 h-40 btn f-24 social-networks__item">
          <i class="fab fa-telegram" />
          {decoration}
        </a>
        {/*<a
          href="https://ru-ru.facebook.com/san4u.ru/"
          target="_blank"
          rel="noopener noreferrer"
          className="w-40 w-md-50 h-md-50 h-40 btn f-24 social-networks__item">
          <i className="fab fa-facebook-f" />
          {decoration}
        </a>*/}
        <a
          href="https://vk.com/san4uru"
          target="_blank"
          rel="noopener noreferrer"
          className="w-40 w-md-50 h-md-50 h-40 btn f-24 social-networks__item">
          <i className="fab fa-vk" />
          {decoration}
        </a>
      </div>
    )
  }
}
