import moment from "moment"
import localization from "moment/locale/ru"
import { CATEGORIES, CATEGORIES_LABEL, CURRENCY } from "../constants"

moment.updateLocale("ru", localization)

export const THUMBNAIL_SIZE = {
  ORIG: "/orig",
  SMALL: "/600",
  SQUARED: "/600x600"
}

export const formatDate = (date) => moment(date * 1000).format("lll")

export const getShortText = (text = "", options = {}) => {
  const { maxWordsCount = 45, maxTextLength = 100 } = options
  const isDefaultWordsCount = !Object.keys(options).includes("maxWordsCount")
  // const isDefaultTextLength = !Object.keys(options).includes('maxTextLength')

  if (isDefaultWordsCount && text.length <= maxTextLength) return text

  if (isDefaultWordsCount && text.length > maxTextLength) {
    const wordsCount = text.slice(0, maxTextLength).split(" ").length
    const result = text.split(" ").slice(0, wordsCount).join(" ")

    return result.length === text.length ? text : `${result}...`
  }

  const result = text.split(" ").slice(0, maxWordsCount).join(" ")

  return result.length === text.length ? text : `${result}...`
}

export const getIdromUrl = () => {
  const pathArray = decodeURIComponent(window.location.pathname)
    .split("/")
    .slice(1)

  return pathArray[pathArray.length - 1]
}

export const getParamFromUrl = (param = "") => {
  const params = new URLSearchParams(window.location.search)

  if (!param) {
    return ""
  }
  if (!params.get(param)) {
    return ""
  }
  return decodeURIComponent(params.get(param))
}

export const forceRefreshRender = () => {
  if (window.AOS && window.AOS.refreshHard) {
    window.AOS.refreshHard()
  }
}

export const getImageThumbnailUrl = (url = "", size = THUMBNAIL_SIZE.SMALL) => {
  const regexp = new RegExp(
    `${Object.values(THUMBNAIL_SIZE)
      .map((size, i, arr) => `\\${size}${i === arr.length - 1 ? "" : "|"}`)
      .join("")}$`,
    "gim"
  )

  return url.replace(regexp, size)
}

export const getFormatedPrice = (price = 0, currency = CURRENCY.RUB) => {
  const SPLITTER_COUNT = 3
  const priceStr = price.toString()

  if (priceStr.length <= SPLITTER_COUNT) return `${priceStr} ${currency}`

  return `${priceStr
    .split("")
    .reverse()
    .reduce((acc, c, i) => {
      return i % SPLITTER_COUNT === 0 ? [...acc, " ", c] : [...acc, c]
    }, [])
    .reverse()
    .join("")
    .trim()} ${currency}`
}

export const getCategoryByCategoryLabel = (label) => {
  for (const category in CATEGORIES) {
    if (CATEGORIES_LABEL[CATEGORIES[category]] === label) {
      return CATEGORIES[category]
    }
  }

  return CATEGORIES.DEFAULT
}

export const isMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )

export const pluralizeStringByCount = (
  count = 0,
  titles = ["штука", "штуки", "штук"]
) => {
  const cases = [2, 0, 1, 1, 1, 2]

  return titles[
    count % 100 > 4 && count % 100 < 20
      ? 2
      : cases[count % 10 < 5 ? count % 10 : 5]
  ]
}

export const isPhoneNumberCorrect = (phone) =>
  phone && /\+7[0-9]{10}/gi.test(phone.replace(/[-_\s)(]/gi, ""))

export const getRetinaImage = (path) => {
  const resolutions = [".jpg", ".jpeg", ".png"]
  const regexp = new RegExp(
    `${resolutions
      .map((res, i, arr) => `\\${res}${i === arr.length - 1 ? "" : "|"}`)
      .join("")}$`,
    "gim"
  )

  return path.replace(regexp, `@2x${path.match(regexp)}`)
}

export const sanitizeUrlText = (text = "") => {
  return text
    .split("_")
    .map((w) => w[0].toUpperCase() + w.slice(1))
    .join(" ")
}
