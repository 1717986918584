import React from "react"
import Spinner from "../Spinner"

const Loader = () => (
  <div>
    <section className="ecommerce_31 bg-light pt-100 pb-50">
      <div className="container px-xl-0">
        <div className="row flex-column flex-lg-row align-items-center justify-content-center">
          <Spinner />
        </div>
      </div>
    </section>
  </div>
)

export default Loader
