let $ = window.$

export function close() {
  SF_close()

  function SF_close() {
    // Navigation dropdown popup

    $('.navigation_popup.opened').removeClass('opened')
    $('.js-open-nav-dropdown i.fa-flip-vertical').removeClass(
      'fa-flip-vertical'
    )
    $('nav').css('z-index', 1)
  }
}
