import React from 'react'
import PropTypes from 'prop-types'
import OwlCarousel from 'react-owl-carousel2'
import './styles.css'

export default class BrandHistory extends React.Component {
  static propTypes = {
    history: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        text: PropTypes.string,
        year: PropTypes.number,
        images: PropTypes.PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            url: PropTypes.string
          })
        )
      })
    ),
    brandName: PropTypes.string,
    isHistoryOpened: PropTypes.bool
  }

  static defaultProps = {
    isHistoryOpened: false
  }

  render() {
    const options = {
      items: 1,
      margin: 20,
      nav: false,
      autoWidth: true,
      center: true,
      dots: true
    }
    return (
      <section
        style={{ height: this.props.isHistoryOpened ? 'auto' : 0 }}
        className="brand-history content_21 ecommerce_19 bg-light mb-55">
        <div className="container-fluid px-xl-0">
          <div className="row">
            <div className="col-12">
              <OwlCarousel options={options}>
                {(this.props.history || []).map(
                  ({ name, text, images = [], year }, index) => (
                    <div
                      key={index}
                      className="brand-history__item mb-30 radius10 d-md-flex flex-md-row-reverse justify-content-between align-items-center product big">
                      <div className="slider w-210 w-lg-420 flex-shrink-0 align-self-stretch radius10 noradius_left">
                        <div className="d-md-flex align-items-stretch slick-slide slick-current slick-active">
                          <img
                            srcSet={images[0] && images[0].url}
                            src={images[0] && images[0].url}
                            alt={images[0] && images[0].name}
                            className="image-cover"
                          />
                        </div>
                      </div>
                      <div className="inner">
                        {name && (
                          <p className="mb-1 d-block link color-main f-md-22 f-20 semibold title pt-20 pb-10">
                            {name}
                          </p>
                        )}
                        <div className="color-heading text-adaptive f-md-18 f-16">
                          {text}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
