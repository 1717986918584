let $ = window.$

export function once () {
  SF_once()

  function SF_once() {
    // Navigation dropdown popup

    if ($('.js-nav-dropdowns').length > 0) {
      $('body').click(function(event) {
        if (
          ($(event.target).closest('.navigation_popup').length === 0 &&
            $(event.target).closest('.js-open-nav-dropdown').length === 0) ||
          $(event.target).closest('.navigation_popup.opened').length !== 0
        ) {
          $('.navigation_popup.opened').removeClass('opened')
          $('.js-open-nav-dropdown i.fa-flip-vertical').removeClass(
            'fa-flip-vertical'
          )
          $('nav').css('z-index', 1)
        }
      })

      $('.js-nav-dropdowns .js-open-nav-dropdown').click(function(event) {
        event.preventDefault()
        $('nav').css('z-index', 1)
        var id = $('.js-nav-dropdowns .js-open-nav-dropdown').index($(this))
        if (
          $('.navigation_popup')
            .eq(id)
            .hasClass('opened')
        ) {
          $(this)
            .find('i')
            .removeClass('fa-flip-vertical')
          $('.navigation_popup')
            .eq(id)
            .removeClass('opened')
        } else {
          $('.navigation_popup.opened').removeClass('opened')
          $('.js-open-nav-dropdown i.fa-flip-vertical').removeClass(
            'fa-flip-vertical'
          )
          $('.navigation_popup')
            .eq(id)
            .addClass('opened')
          $(this)
            .find('i')
            .addClass('fa-flip-vertical')
          $(this)
            .closest('nav')
            .css('z-index', 1001)
        }
      })
    }
  }
}
