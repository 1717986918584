import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import "./styles.css"
import { Link } from "react-router-dom"
import { getImageThumbnailUrl, Routes, THUMBNAIL_SIZE } from "../../utils"

export default class CollectionPreview extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    pdf: PropTypes.string,
    text: PropTypes.string,
    catalogs: PropTypes.array,
    brandName: PropTypes.string,
    brandUrl: PropTypes.string
  }

  constructor(props) {
    super(props)

    const { catalogs } = this.props

    this.state = { isMoreOpened: false }
    this.interierPhotos = []
    this.colorPhotos = []

    if (!catalogs) return
    if (catalogs.length === 1) {
      this.interierPhotos = catalogs[0]
      return
    }

    this.colorPhotos = catalogs[0]
    this.interierPhotos = catalogs[1]
  }

  render() {
    const { name, brandUrl, id } = this.props
    const titleImage = this.interierPhotos[0] && this.interierPhotos[0].url
    const imgUrl = getImageThumbnailUrl(titleImage, THUMBNAIL_SIZE.SQUARED)
    const link = Routes.collectionByBrandRoute.build({ brand: brandUrl, id })

    return (
      <div className={classnames('container', 'collection-preview')}>
        <div
          className="product overflow-hidden collection-preview__product h-full d-flex align-items-center justify-content-between"
          data-aos-duration={300}
          data-aos-once="true"
          data-aos="fade-down"
          data-aos-delay={0}>
          <div className="relative holder h-full overflow-hidden">
            <img
              srcSet={`${imgUrl} 2x`}
              src={imgUrl}
              alt={`Коллекция ${name}`}
              className="w-full h-full relative radius10 img-fluid image-cover"
            />
            <div className="collection-preview__more op-0 w-full d-none d-md-flex h-full radius10 flex-column justify-content-center inner">
              <Link to={link} className="btn sm action-1 ml-15 mr-15 medium">
                Подробнее
              </Link>
            </div>
          </div>
          <div className="mt-25 text-center relative w-full c-pointer text-truncate">
            <span className="link color-main f-22 semibold title collection-preview__title">
              {name}
            </span>
          </div>
          <Link
            to={link}
            className="btn sm border-action-1 ml-15 mr-15 mt-15 d-md-none d-flex">
            Подробнее
          </Link>
        </div>
      </div>
    )
  }
}
