import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { getShortText, Routes } from "../../utils"
import Spinner from "../Spinner"

export default class BrandHistoryPreview extends React.Component {
  static propTypes = {
    brand: PropTypes.shape({
      logo: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      url: PropTypes.string
    })
  }

  static defaultProps = {
    isLoading: false
  }

  render() {
    const { brand } = this.props
    const link = brand ? Routes.exactBrand.build({ id: brand.url }) : ""

    return (
      <section className="content_16 bg-purple pt-95 pb-85 color-white">
        <div className="container px-xl-0">
          {brand ? (
            <div className="row align-items-center justify-content-center justify-content-md-start">
              <div className="col-lg-1" />
              <div
                className="col-xl-2 col-md-3"
                data-aos-duration={300}
                data-aos="fade-down"
                data-aos-delay={0}
              >
                <img
                  srcSet={brand.logo}
                  src={brand.logo}
                  className="img-fluid collection__brand-logo"
                  alt={brand.name}
                />
              </div>
              <div className="d-flex d-md-block flex-column col-xl-7 col-lg-8 col-md-9 mt-4 mt-md-0 justify-content-center justify-content-md-start">
                <h2
                  className="pl-15 small text-center text-md-left"
                  data-aos-duration={300}
                  data-aos="fade-down"
                  data-aos-delay={150}
                >
                  {`История ${brand ? brand.name : "бренда"}`}
                </h2>
                <div className="mt-15 pl-15 f-18 medium op-7 text-adaptive">
                  <span
                    data-aos-duration={300}
                    data-aos="fade-down"
                    data-aos-delay={300}
                  >
                    {getShortText(brand.description)}
                  </span>
                </div>
                <Link
                  to={link}
                  data-aos-duration={300}
                  data-aos="fade-down"
                  data-aos-delay={450}
                  className="mt-15 ml-15 btn border-action-1"
                >
                  Узнать больше
                </Link>
              </div>
            </div>
          ) : (
            <div className="row align-items-center justify-content-center">
              <Spinner color="#ffffff" />
            </div>
          )}
        </div>
      </section>
    )
  }
}
