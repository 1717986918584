import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { CATEGORIES, CATEGORIES_LABEL } from '../../constants'
import { THEME } from '../../constants/dropdown'

export default class Dropdown extends React.Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    theme: PropTypes.oneOf(Object.values(THEME)),
    dropup: PropTypes.bool
  }

  static defaultProps = {
    value: CATEGORIES_LABEL[CATEGORIES.DEFAULT],
    className: '',
    theme: THEME.DEFAULT,
    dropup: false
  }

  onChange = (e, item) => {
    e && e.preventDefault()

    this.props.onChange(item)
  }

  render() {
    return (
      <div
        className={classnames(
          'd-inline-block dropdown categories-dropdown',
          this.props.className,
          { dropup: this.props.dropup }
        )}>
        <a
          href="/"
          className={classnames({
            'btn sm action-1 color-main f-14 semibold text-uppercase sp-20 dropdown-toggle':
              this.props.theme === THEME.YELLOW_VALUE,
            'link color-main f-14 semibold text-uppercase sp-20 dropdown-toggle':
              this.props.theme === THEME.DEFAULT
          })}
          id="ecommerce_5_dropdown"
          onClick={e => e && e.preventDefault()}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false">
          {this.props.value}
        </a>
        <div
          className="ml-0 bg-light px-0 pt-15 pb-15 radius16 dropdown-menu dropdown-menu-right"
          aria-labelledby="ecommerce_5_dropdown">
          {this.props.items.map(item => (
            <div className="py-1 px-15 link_holder" style={{ pointerEvents: 'all' }} key={item}>
              <a
                href="/"
                onClick={e => this.onChange(e, item)}
                className="link color-heading">
                {item}
              </a>
            </div>
          ))}
        </div>
      </div>
    )
  }
}
