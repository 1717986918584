import React from "react"
import { CONTACT_TYPE } from "./contacts"

export const contacts = [
  {
    title: 'Салон на Ленинском',
    title2: 'Ленинский пр-т, 24',
    titleLink:
      'https://yandex.ru/maps/213/moscow/?ll=37.590200%2C55.714658&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fll%3D37.590206%252C55.714657%26spn%3D0.001000%252C0.001000%26text%3D%25D0%25A0%25D0%25BE%25D1%2581%25D1%2581%25D0%25B8%25D1%258F%252C%2520%25D0%259C%25D0%25BE%25D1%2581%25D0%25BA%25D0%25B2%25D0%25B0%252C%2520%25D0%259B%25D0%25B5%25D0%25BD%25D0%25B8%25D0%25BD%25D1%2581%25D0%25BA%25D0%25B8%25D0%25B9%2520%25D0%25BF%25D1%2580%25D0%25BE%25D1%2581%25D0%25BF%25D0%25B5%25D0%25BA%25D1%2582%252C%252024%2520&z=16',
    text: (
      <React.Fragment>
        Пн-Пт: 10:00 - 20:00
        <br />
        Сб-Вс: 11:00 - 19:00
      </React.Fragment>
    ),
    contacts: [
      {
        type: CONTACT_TYPE.TEL,
        value: '+7 (495) 114-54-48',
        link: 'tel:+74951145448'
      },
      {
        type: CONTACT_TYPE.EMAIL,
        value: 'sales@san4u.ru',
        link: 'mailto:sales@san4u.ru',
        htmlOptions: { 'data-toggle': 'modal', 'data-target': '#mailModal' }
      }
    ]
  },
  {
    title: 'Салон на Рублёвском',
    title2: (
      <React.Fragment>
        Рублёвское шоссе, 52А
      </React.Fragment>
    ),
    titleLink:
      'https://yandex.ru/maps/213/moscow/house/rublyovskoye_shosse_52a/Z04YcgdiTkEHQFtvfXt0dHhgYA==/?indoorLevel=1&ll=37.402740%2C55.755434&utm_source=share&z=16',
    text: (
      <React.Fragment>
        Пн-Пт: 10:00 - 20:00
        <br />
        Сб-Вс: 11:00 - 19:00
      </React.Fragment>
    ),
    contacts: [
      {
        type: CONTACT_TYPE.TEL,
        value: '+7 (495) 662-99-19',
        link: 'tel:+74956629919'
      },
      {
        type: CONTACT_TYPE.EMAIL,
        value: 'sales@san4u.ru',
        link: 'mailto:sales@san4u.ru',
        htmlOptions: { 'data-toggle': 'modal', 'data-target': '#mailModal' }
      }
    ]
  },
  {
    title: 'Оптовый отдел',
    titleLink:
      'https://yandex.ru/maps/213/moscow/?ll=37.590200%2C55.714658&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fll%3D37.590206%252C55.714657%26spn%3D0.001000%252C0.001000%26text%3D%25D0%25A0%25D0%25BE%25D1%2581%25D1%2581%25D0%25B8%25D1%258F%252C%2520%25D0%259C%25D0%25BE%25D1%2581%25D0%25BA%25D0%25B2%25D0%25B0%252C%2520%25D0%259B%25D0%25B5%25D0%25BD%25D0%25B8%25D0%25BD%25D1%2581%25D0%25BA%25D0%25B8%25D0%25B9%2520%25D0%25BF%25D1%2580%25D0%25BE%25D1%2581%25D0%25BF%25D0%25B5%25D0%25BA%25D1%2582%252C%252024%2520&z=16',
    text: (
      <React.Fragment>
        Пн-Пт: 10:00 - 18:00
        <br />
        Сб-Вс: Выходной
      </React.Fragment>
    ),
    contacts: [
      {
        type: CONTACT_TYPE.TEL,
        value: '+7 (495) 114-54-50',
        link: 'tel:+74951145450'
      },
      {
        type: CONTACT_TYPE.EMAIL,
        value: 'opt@san4u.ru',
        link: 'mailto:opt@san4u.ru',
        htmlOptions: { 'data-toggle': 'modal', 'data-target': '#mailModal' }
      }
    ]
  },
  {
    title: 'Отдел доставки',
    titleLink:
      'https://yandex.ru/maps/213/moscow/?ll=37.590200%2C55.714658&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fll%3D37.590206%252C55.714657%26spn%3D0.001000%252C0.001000%26text%3D%25D0%25A0%25D0%25BE%25D1%2581%25D1%2581%25D0%25B8%25D1%258F%252C%2520%25D0%259C%25D0%25BE%25D1%2581%25D0%25BA%25D0%25B2%25D0%25B0%252C%2520%25D0%259B%25D0%25B5%25D0%25BD%25D0%25B8%25D0%25BD%25D1%2581%25D0%25BA%25D0%25B8%25D0%25B9%2520%25D0%25BF%25D1%2580%25D0%25BE%25D1%2581%25D0%25BF%25D0%25B5%25D0%25BA%25D1%2582%252C%252024%2520&z=16',
    text: (
      <React.Fragment>
        Пн-Пт: 10:00 - 18:00
        <br />
        Сб-Вс: Выходной
      </React.Fragment>
    ),
    contacts: [
      {
        type: CONTACT_TYPE.TEL,
        value: '+7 (495) 532-30-45',
        link: 'tel:+74955323045'
      },
      {
        type: CONTACT_TYPE.WHATSAPP,
        value: '+7 (926) 360-74-44',
        link: 'https://wa.me/79263607444',
        htmlOptions: { target: '_blank', rel: 'noopener noreferrer' }
      },
      {
        type: CONTACT_TYPE.EMAIL,
        value: 'dostavka@san4u.ru',
        link: 'mailto:dostavka@san4u.ru',
        htmlOptions: { 'data-toggle': 'modal', 'data-target': '#mailModal' }
      }
    ]
  },
  {
    title: 'Технический отдел',
    titleLink:
      'https://yandex.ru/maps/213/moscow/?ll=37.590200%2C55.714658&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fll%3D37.590206%252C55.714657%26spn%3D0.001000%252C0.001000%26text%3D%25D0%25A0%25D0%25BE%25D1%2581%25D1%2581%25D0%25B8%25D1%258F%252C%2520%25D0%259C%25D0%25BE%25D1%2581%25D0%25BA%25D0%25B2%25D0%25B0%252C%2520%25D0%259B%25D0%25B5%25D0%25BD%25D0%25B8%25D0%25BD%25D1%2581%25D0%25BA%25D0%25B8%25D0%25B9%2520%25D0%25BF%25D1%2580%25D0%25BE%25D1%2581%25D0%25BF%25D0%25B5%25D0%25BA%25D1%2582%252C%252024%2520&z=16',
    text: (
      <React.Fragment>
        Пн-Пт: 10:00 - 18:00
        <br />
        Сб-Вс: Выходной
      </React.Fragment>
    ),
    contacts: [
      {
        type: CONTACT_TYPE.TEL,
        value: '+7 (985) 450-81-00',
        link: 'tel:+79854508100'
      }
    ]
  },
  {
    title: '',
    text: (
      <React.Fragment>
        ООО «Европейская Сантехника» <br />
        Юр.адрес: 119071, г. Москва, Ленинский проспект, д.24, помещение IV, V{' '}
        <br />
        ОГРН 1187746916268 <br />
        ОКПО 34107583 <br />
        ИНН 7725498883 <br />
        КПП 772501001 <br />
        (ПАО) СБЕРБАНК <br />
        БИК 044525225 <br />
        к/сч 30101810400000000225 <br />
        р/сч 40702810138000150450
      </React.Fragment>
    )
  }
]
