/* brands */
export const fetchBrandsListPath = '/api/brands/'
export const fetchBrandPath = '/api/brand/'
export const fetchBrandCollectionPath = '/api/brand_collection/'

/* items */
export const fetchItemsListPath = '/api/items/'
export const fetchExactItemPath = '/api/item/'

/* news */
export const fetchNewsListPath = '/api/news/'
export const fetchExactNewsPath = '/api/exact_news/'

/* projects */
export const projects = '/api/projects/'
export const project = '/api/project/'

/* suggest */
export const suggestBrands = '/api/suggest/brands/'
