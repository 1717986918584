import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { ITEMS_LABEL_BY_TYPES, ITEMS_TYPES } from "../../constants"
import "./styles.css"

const EXCLUDED_TYPES = [
  ITEMS_TYPES.NOT_IN_STOCK,
  ITEMS_TYPES.SOLD,
  ITEMS_TYPES.PROMO
]
const FILTERS_ARRANGEMENT = [
  ITEMS_TYPES.DEFAULT,
  ITEMS_TYPES.IN_STOCK,
  ITEMS_TYPES.SALE,
  ITEMS_TYPES.NEW,
  ITEMS_TYPES.LENINSKY,
]

export default class ItemsFilterNav extends React.Component {
  static propTypes = {
    value: PropTypes.oneOf(Object.values(ITEMS_TYPES)),
    onChange: PropTypes.func.isRequired
  }

  static defaultProps = {
    value: ITEMS_TYPES.DEFAULT
  }

  render() {
    const { value, onChange } = this.props

    return (
      <div
        className="f-14 semibold text-uppercase sp-20 categories items-filter-nav"
        data-aos-duration={300}
        data-aos="fade-down"
        data-aos-delay={0}
      >
        {FILTERS_ARRANGEMENT.filter((t) => !EXCLUDED_TYPES.includes(t)).map(
          (type) => (
            <a
              key={type}
              href="/"
              className={classnames(
                "mr-25 link color-heading",
                "items-filter-nav__item",
                {
                  "items-filter-nav__item_checked": type === value
                }
              )}
              onClick={(e) => {
                e && e.preventDefault()
                onChange({ type })
              }}
            >
              {ITEMS_LABEL_BY_TYPES[type]}
            </a>
          )
        )}
      </div>
    )
  }
}
