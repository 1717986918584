import React from "react"
import CollectionPreview from "../CollectionPreview"
import Loader from "../Loader"
import PropTypes from "prop-types"
import "./styles.css"
import { forceRefreshRender } from "../../utils"
import classnames from "classnames"
import Masonry from "react-masonry-component"

export default class CollectionList extends React.Component {
  static propTypes = {
    collections: PropTypes.arrayOf({
      name: PropTypes.string,
      pdf: PropTypes.string,
      text: PropTypes.string,
      catalogs: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          name: PropTypes.string
        })
      )
    }),
    brandName: PropTypes.string.isRequired,
    brandUrl: PropTypes.string.isRequired
  }

  state = { isLoading: true, opacity: true }

  componentDidMount() {
    this.timer = setTimeout(() => {
      this.setState({ isLoading: false })
      forceRefreshRender()
    }, 300)
  }

  componentWillUnmount() {
    this.timer && clearTimeout(this.timer)
  }

  onLayoutComplete = () => {
    if (this.state.opacity) this.setState({ opacity: false })
    this.timer = setTimeout(() => {
      this.setState({ isLoading: false })
      forceRefreshRender()
    }, 300)
  }

  render() {
    const { collections = [], brandUrl, brandName } = this.props

    if (!collections.length || this.state.isLoading) {
      return <Loader />
    }

    return (
      <section
        className="justify-content-start container"
        data-aos-duration={300}
        data-aos-once="true"
        data-aos="fade-down"
        data-aos-delay={0}
      >
        {this.state.opacity ? (
          <div className="absolute h-full w-full d-flex justify-content-center">
            <Loader />
          </div>
        ) : null}
        <Masonry
          className={classnames(
            "row align-items-lg-stretch justify-content-start",
            {
              "op-0": this.state.opacity
            }
          )}
          onLayoutComplete={this.onLayoutComplete}
          options={{ percentPosition: 20, horizontalOrder: true }}
        >
          {collections.map((collection) => (
            <div className="col-12 col-xs-12 col-sm-6 col-md-4 col-md-3 col-xl-2 overflow-hidden">
              <CollectionPreview
                key={collection.name}
                {...{
                  ...collection,
                  brandUrl,
                  brandName
                }}
              />
            </div>
          ))}
        </Masonry>
      </section>
    )
  }
}
