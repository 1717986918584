import React from "react"
import PropTypes from "prop-types"
import OwlCarousel from "react-owl-carousel2"
import Showcase from "../Showcase"
import { getImageThumbnailUrl, THUMBNAIL_SIZE } from "../../utils"

export default class CollectionMoreInfo extends React.Component {
  static propTypes = {
    interierPhotos: PropTypes.array,
    colorPhotos: PropTypes.array
  }

  render() {
    const { interierPhotos, colorPhotos } = this.props
    const options = {
      itemsCustom: [
        [0, 1],
        [400, 2],
        [700, 3],
        [1000, 4],
        [1200, 5],
        [1600, 6],
        [3000, 8]
      ],
      slideBy: 1,
      responsive: false,
      dots: false
    }

    return (
      <React.Fragment>
        <div className="d-flex px-15 pt-25 colors-carousel container px-xl-0">
          <OwlCarousel options={options}>
            {Array.isArray(colorPhotos) &&
              colorPhotos.map((colorImagePath) => (
                <div className="colors-carousel__item" key={colorImagePath.url}>
                  <a
                    key={colorImagePath.url}
                    data-fancybox="showcase_4"
                    href={colorImagePath.url}
                    className="w-full d-block link"
                  >
                    <img
                      srcSet={`${getImageThumbnailUrl(
                        colorImagePath.url,
                        THUMBNAIL_SIZE.SQUARED
                      )} 2x`}
                      src={getImageThumbnailUrl(
                        colorImagePath.url,
                        THUMBNAIL_SIZE.SQUARED
                      )}
                      className="radius6 img-fluid colors-carousel__item__img"
                      alt={colorImagePath.name}
                    />
                  </a>
                </div>
              ))}
          </OwlCarousel>
        </div>

        <Showcase gallery={interierPhotos} />
      </React.Fragment>
    )
  }
}
