import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import CallOrderButton from "../CallOrderButton"
import { getFormatedPrice } from "../../utils"
import "./styles.css"

const Price = ({
  price,
  old_price: oldPrice,
  sale,
  saleIcon,
  modalButtonLabel = "Заказать звонок",
  showModalButton = true
}) => {
  if (!price) {
    return showModalButton ? (
      <div className="price price_absent">
        <CallOrderButton label={modalButtonLabel} type="price" />{" "}
      </div>
    ) : null
  }

  const formattedPrice = getFormatedPrice(price)
  const formattedOldPrice = getFormatedPrice(oldPrice)

  const saleBadge = sale ? (
    <div className="price__sale">
      {sale}
      <img src={saleIcon} alt={sale} />
    </div>
  ) : null

  return (
    <div className="price price-container pt-15">
      {oldPrice && (
        <a
          href="/"
          className="link medium f-16 price__old-price"
          onClick={(e) => e && e.preventDefault()}
        >
          {formattedOldPrice}
        </a>
      )}
      {price && (
        <a
          href="/"
          className={classnames("link action-1 medium price__actual-price", {
            "f-22": !oldPrice,
            "f-24": !!oldPrice
          })}
          onClick={(e) => e && e.preventDefault()}
        >
          {formattedPrice}
          {saleBadge}
        </a>
      )}
    </div>
  )
}

Price.propTypes = {
  price: PropTypes.number,
  old_price: PropTypes.number,
  sale: PropTypes.number,
  modalButtonLabel: PropTypes.string,
  showModalButton: PropTypes.bool
}

export default Price
