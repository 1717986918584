import { CATEGORIES, CATEGORIES_LABEL, ROUTES } from "../constants/default"

export const SECTIONS = {
  ABOUT: { label: "О компании", link: "" },
  DIRECTIONS: { label: "Направления", link: "" },
  BRANDS: { label: "Бренды", link: ROUTES.BRANDS }, // '' },
  // PROJECTS: { label: 'Проекты', link: ROUTES.PROJECTS },
  SALES: { label: "Акции", link: ROUTES.SALES },
  CONTACTS: { label: "Контакты", link: ROUTES.CONTACT }
}

export const SUB_SECTIONS_LABEL = {
  ABOUT: "ABOUT",
  NEWS: "NEWS",
  SERVICES: "SERVICES",
  PROJECTS: "PROJECTS"
}

export const SUB_SECTIONS = {
  [SUB_SECTIONS_LABEL.ABOUT]: {
    label: "О нас",
    link: ROUTES.ABOUT,
    image: "/i/navigation_25_img_1.png",
    description: "История компании, что нас выделяет"
  },
  [SUB_SECTIONS_LABEL.NEWS]: {
    label: "Новости",
    link: ROUTES.NEWS,
    image: "/i/navigation_25_img_2.png",
    description: "Самые новые события"
  },
  [SUB_SECTIONS_LABEL.SERVICES]: {
    label: "Сервис",
    link: ROUTES.SERVICES,
    image: "/i/navigation_25_img_3.png",
    description: "Доставка, монтаж, проектирование"
  },
  [SUB_SECTIONS_LABEL.PROJECTS]: {
    label: "Проекты",
    link: ROUTES.PROJECTS,
    image: "/i/navigation_25_img_4.png",
    description: "Магия безупречных интерьеров"
  }
}

export const DIRECTIONS = {
  [CATEGORIES.SANITARY]: {
    label: CATEGORIES_LABEL[CATEGORIES.SANITARY],
    image: "/i/portfolio-1.jpg",
    description: "Лучшие бренды от лучших производителей",
    link: ROUTES.SANITARY
  },
  [CATEGORIES.TILE]: {
    label: CATEGORIES_LABEL[CATEGORIES.TILE],
    image:
      "https://storage.yandexcloud.net/san4u/6ad67a1d-0f30-4706-9391-b4cd0b32c3ba/orig",
    description: "Обновленный ассортимент изделий",
    link: ROUTES.TILES
  },
  [CATEGORIES.STONE]: {
    label: CATEGORIES_LABEL[CATEGORIES.STONE],
    image: "/i/portfolio-3.jpg",
    description: "Роскошь, изыск и комфорт у вас дома",
    link: ROUTES.STONE
  },
  [CATEGORIES.SAUNA]: {
    label: CATEGORIES_LABEL[CATEGORIES.SAUNA],
    image:
      "https://storage.yandexcloud.net/san4u/b8602632-c7f2-43a3-b50c-eb966537af53/orig",
    description: "Создание и обустройство саун",
    link: ROUTES.SAUNA
  },
  [CATEGORIES.HEATING]: {
    label: CATEGORIES_LABEL[CATEGORIES.HEATING],
    image:
      "https://storage.yandexcloud.net/san4u/8ecaa43f-08b2-430a-af42-1339d4c00df4/orig",
    description: "Забота об оптимальной температуре",
    link: ROUTES.HEATING
  },
  [CATEGORIES.MOSAIC]: {
    label: CATEGORIES_LABEL[CATEGORIES.MOSAIC],
    image:
      "https://storage.yandexcloud.net/san4u/9942c4d1-6fa6-4b68-83fe-91ffedf6715c/orig",
    description: "Обновленный ассортимент изделий",
    link: ROUTES.MOSAIC
  }
}
