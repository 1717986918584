import { constructUrl } from "./router"

const buildLink = (params, pattern) => {
  const { pathname, search } = constructUrl({ params, pattern })

  return `${pathname}${search}`
}

export default class Routes {
  static exactNews = {
    pattern: "/news/<id>",
    build: (params) => buildLink(params, Routes.exactNews.pattern)
  }

  static exactItem = {
    pattern: "/item/<id>",
    build: (params) => buildLink(params, Routes.exactItem.pattern)
  }

  static exactBrand = {
    pattern: "/brands/<id>",
    build: (params) => buildLink(params, Routes.exactBrand.pattern)
  }

  static brandsRoute = {
    pattern: "/brands(?aspect=<aspect>)",
    build: (params) => buildLink(params, Routes.brandsRoute.pattern)
  }

  static collectionByBrandRoute = {
    pattern: "/collection/<id>?brand=<brand>",
    build: (params) => buildLink(params, Routes.collectionByBrandRoute.pattern)
  }

  static exactProject = {
    pattern: "/projects/<id>",
    build: (params) => buildLink(params, Routes.exactProject.pattern)
  }
}
