import React from "react"
import classnames from "classnames"
import { CONTACT_TYPE } from "../../constants"

const getIconForContactType = contactType => {
  switch (contactType) {
    case CONTACT_TYPE.TEL:
      return 'fas fa-phone'
    case CONTACT_TYPE.EMAIL:
      return 'fas fa-envelope'
    case CONTACT_TYPE.WHATSAPP:
      return 'fab fa-whatsapp'
    default:
      return ''
  }
}

const ContactBlock = ({ title, title2, text, contacts = [], titleLink = '' }) => (
  <div className="mb-0 mb-lg-0 col-xl-4 col-lg-4 col-md-8 text-center">
    <a
      className="small f-24 bold link-unstyled"
      data-aos-duration={300}
      data-aos="fade-down"
      data-aos-delay={0}
      href={titleLink || '/'}
      {...(!titleLink ? { onClick: e => e && e.preventDefault() } : {})}
      target="_blank"
      rel="noopener noreferrer">
      {title}
    </a>
    {title2 && (<p
      className="mt-10 color-main">
      {title2}
    </p>)}
    <div data-aos-duration={300} data-aos="fade-down" data-aos-delay={150}>
      {contacts.map(({ link, value, type, htmlOptions = {} }) => (
        <a
          href={link}
          key={value}
          {...htmlOptions}
          className="mt-10 link color-main f-18 medium d-flex align-items-baseline justify-content-center">
          <i
            className={classnames(
              'w-30 fa-flip-horizontal flex-shrink-0 f-14 text-right action-1',
              getIconForContactType(type)
            )}
          />{' '}
          <div>{value}</div>
        </a>
      ))}
    </div>

    <div
      className="mt-20 mb-50 mb-md-70 color-heading"
      data-aos-duration={300}
      data-aos="fade-down"
      data-aos-delay={300}>
      {text}
    </div>
  </div>
)
export default ContactBlock;
