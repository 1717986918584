import React from 'react';
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './styles.css'

export default class DownloadFileButton extends React.Component {
  static propTypes = {
    label: PropTypes.string || PropTypes.element,
    fileLink: PropTypes.string,
    small: PropTypes.bool,
    className: PropTypes.string
  };

  static defaultProps = {
    label: "Скачать",
    fileLink: "",
    small: false,
    className: ''
  };

  render() {
    const { small } = this.props;

    return <div className={classnames(
      "collection-preview__download d-flex align-items-center",
      { 'collection-preview__download--small': small },
      this.props.className
    )}>
      <img className="c-pointer collection-preview__download__icon" src="/i/download_document_icon.svg" alt="" />
      <span title={this.props.label} className={classnames("link color-main title collection-preview__download__label", {
        'f-22 semibold': !small,
        'f-14 sm': small
      })}>
        {this.props.label}
      </span>
    </div>
  }
}