import React from 'react'

const Spinner = props => (
  <div
    className="spinner-border"
    role="status"
    {...(props.color ? { style: { color: props.color } } : {})}>
    <span className="sr-only">Загрузка...</span>
  </div>
)

export default Spinner
