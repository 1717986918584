import { BASE_URL, ADMIN_URL } from "../constants/default"

export const isDevEnv = () =>
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1"

export const isTestEnv = () =>
  isDevEnv() ||
  window.location.origin.includes(BASE_URL.TEST) ||
  window.location.origin.includes(ADMIN_URL.TEST)

export const mainSiteUrl = isTestEnv() ? BASE_URL.TEST : BASE_URL.PROD

export const adminSiteUrl = isTestEnv() ? ADMIN_URL.TEST : ADMIN_URL.PROD

export const constructUrl = ({ baseUrl, params, pathname, pattern }) => {
  if (pattern) {
    return buildUrlByPattern({ baseUrl, params, pattern })
  }

  return buildUrl({ baseUrl, params, pathname })
}

const buildUrlByPattern = ({
  baseUrl = mainSiteUrl,
  params = {},
  pattern = ""
}) => {
  if (!Object.values(params).length && !pattern.length) {
    return new URL("", baseUrl)
  }

  const patternRegexp = new RegExp("<(.+?)>", "gim")
  let patternString = pattern.slice().split("?")[0]
  let paramsPatternString = pattern.slice().split("?")[1]

  patternString = (patternString.match(patternRegexp) || []).reduce(
    (acc, matchedParam) => {
      const paramName = matchedParam.slice(1, matchedParam.length - 1)

      if (params[paramName] !== undefined) {
        const param = params[paramName]

        delete params[paramName]
        return acc + patternString.replaceAll(matchedParam, param)
      }
      return acc
    },
    ""
  )

  if (!paramsPatternString) {
    return new URL(patternString, baseUrl)
  }

  return buildUrl({ params, pathname: patternString })
}

const buildUrl = ({ baseUrl = mainSiteUrl, params = {}, pathname = "" }) => {
  if (!Object.values(params).length) {
    return new URL(pathname, baseUrl)
  }

  const url = new URL(pathname, baseUrl)
  Object.keys(params).map((param) =>
    url.searchParams.append(param, encodeURIComponent(params[param]))
  )

  return url
}
